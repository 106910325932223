import React from 'react';
import { graphql } from 'gatsby';

import HeroTypeA from '../../components/Heroes/HeroTypeA';
import LocationMap from '../../components/Location/LocationMap';
import StoreFacts from '../../components/Location/StoreFacts';
import HowToOrderOnline from '../../components/Banners/HowToOrderOnline';
import CategorySection from '../../components/Category Section/CategorySection';
import BannerInfoTypeA from '../../components/Banners/BannerInfoTypeA';
// SUPER HEADER CUSTOM
import BannerTypeA from '../../components/Banners/BannerTypeA';
import LocationSearchSection from '../../components/Location/LocationSearchSection';
import FaqSection from '../../components/FAQ/FaqSection';
import SEO from '../../components/SEO/SEO';

export default function KingstonLocation({ data }) {
  const hero_type_a = data.strapiKeswickLocation.Hero;
  const hours_of_op = data.strapiKeswickLocation.HoursOfOp;
  const quick_store_facts = data.strapiKeswickLocation.StoreFacts;
  // const category = data.strapiKeswickLocation.Category_Section;
  const info_banner_type_a = data.strapiKeswickLocation.InfoBannerA;
  const banner_type_middle = data.strapiKeswickLocation.BlogBannerA;
  // const location_search = data.strapiLocationsSearchComponent.Location_With_Search;
  const faq = data.strapiKeswickLocation.Faq;
  const banner_type_bottom = data.strapiKeswickLocation.BlogBannerABottom;

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Store',
    name: 'House of Cannabis',
    image: '/logo.jpg',
    '@id': '',
    url: 'https://thehousecannabis.ca/kingston/location',
    telephone: '(519)-714-0420',
    priceRange: '$',
    address: {
      '@type': 'PostalAddress',
      streetAddress: '208 Division Street',
      addressLocality: 'Kingston',
      addressRegion: 'ON',
      postalCode: 'K7K 3Z1',
      addressCountry: 'CA',
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 44.2342596,
      longitude: -76.4956145,
    },
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ],
        opens: '10:00',
        closes: '21:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Sunday',
        opens: '11:00',
        closes: '18:00',
      },
    ],
  };

  const specialHrs = "CANADA DAY: 9AM-6PM";

  return (
    <>
      <SEO
        title="The Best Cannabis Store in Keskwick | House of Cannabis"
        description="Located at 208 Division Street, our team’s unwavering 
                  commitment to offering the best customer service has helped make 
                  us Kingston’s #1 weed dispensary."
        schemaMarkup={schema}
      />
      <HeroTypeA
        title={hero_type_a.Text}
        buttonLabel={hero_type_a.Button.Label}
        heroImage={
          hero_type_a.BackgroundImage.localFile.childImageSharp.gatsbyImageData
        }
        buttonLink={hero_type_a.Button.Custom_Link_Slug}
        hasGradient
      />

      <LocationMap
        title={hours_of_op.Title}
        street={hours_of_op.Address}
        hours={hours_of_op.Day_And_Hours}
        orderSlug={hours_of_op.Order_Online_Link_Slug}
        exactAddress={hours_of_op.Address_Link}
        long={hours_of_op.long}
        lat={hours_of_op.lat}
        specialHrs={specialHrs}
      />

      <StoreFacts
        storeFacts={quick_store_facts}
        storeHours={hours_of_op.Day_And_Hours}
        specialHrs={specialHrs}
      />

      <HowToOrderOnline loc_slug="/keswick/menu" />

      {/* <CategorySection categorydata={category.Block} title={category.Title} /> */}

      <BannerInfoTypeA
        title="There are Plenty of Reasons to Choose House of Cannabis"
        description={info_banner_type_a.Content}
        bgImage={
          info_banner_type_a.Image.localFile.childImageSharp.gatsbyImageData
        }
      />

      <BannerTypeA
        title={banner_type_middle.Article_Link.Title}
        description={banner_type_middle.Article_Link.Preview_Text}
        bannerImage={
          banner_type_middle.Article_Link.BannerImage.localFile.childImageSharp
            .gatsbyImageData
        }
        link="/menu"
        buttonLabel="READ ARTICLE"
      />

      {/*     {`/blog/${banner_type_middle.Article_Link.slug}`} */}

      {/* <LocationSearchSection 
                titles={location_search.Title}
                locations={location_search.Location_Single}
            /> */}

      <FaqSection faqdata={faq.FAQs} title={faq.Title} />

      <BannerTypeA
        title={banner_type_bottom.Article_Link.Title}
        description={banner_type_bottom.Article_Link.Preview_Text}
        bannerImage={
          banner_type_bottom.Article_Link.BannerImage.localFile.childImageSharp
            .gatsbyImageData
        }
        link="/menu"
        buttonLabel="READ ARTICLE"
      />
    </>
  );
}

/* {`/blog/${banner_type_bottom.Article_Link.slug}`} */

export const query = graphql`
  query {
    strapiKeswickLocation {
      BlogBannerA {
        Article_Link {
          Title
          Preview_Text
          slug
          BannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
      }
      BlogBannerABottom {
        Article_Link {
          Preview_Text
          Title
          slug
          BannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
      }
      Faq {
        Title
        FAQs {
          Answer
          Question
        }
      }
      Hero {
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
        Text
        Button {
          Custom_Link_Slug
          Label
        }
      }
      HoursOfOp {
        Address
        Address_Link
        Day_And_Hours {
          Day
          Hours
        }
        Order_Online_Link_Slug
        Title
        Visit_This_Location_Link_Slug
        long
        lat
      }
      InfoBannerA {
        Content
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
      }
      StoreFacts {
        Contact_Email
        Contact_Phone
        Location_Adress
        Location_City
      }
    }
  }
`;
